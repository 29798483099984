package io.writeopia.sdk.sql

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String
import kotlin.collections.Collection

public class DocumentEntityQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> selectAll(mapper: (
    id: String,
    title: String,
    created_at: Long,
    last_updated_at: Long,
    user_id: String,
    favorite: Long,
    parent_document_id: String,
  ) -> T): Query<T> = Query(344_877_019, arrayOf("documentEntity"), driver, "DocumentEntity.sq",
      "selectAll", """
  |SELECT *
  |FROM documentEntity
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getString(6)!!
    )
  }

  public fun selectAll(): Query<DocumentEntity> = selectAll { id, title, created_at,
      last_updated_at, user_id, favorite, parent_document_id ->
    DocumentEntity(
      id,
      title,
      created_at,
      last_updated_at,
      user_id,
      favorite,
      parent_document_id
    )
  }

  public fun <T : Any> selectWithContent(mapper: (
    id: String,
    title: String,
    created_at: Long,
    last_updated_at: Long,
    user_id: String,
    favorite: Long,
    parent_document_id: String,
    id_: String?,
    local_id: String?,
    type: Long?,
    parent_id: String?,
    url: String?,
    path: String?,
    text: String?,
    checked: Long?,
    position: Long?,
    document_id: String?,
    is_group: Long?,
    has_inner_steps: Long?,
    background_color: Long?,
    tags: String?,
  ) -> T): Query<T> = Query(1_713_038_605, arrayOf("documentEntity", "storyStepEntity"), driver,
      "DocumentEntity.sq", "selectWithContent", """
  |SELECT *
  |FROM documentEntity
  |LEFT JOIN storyStepEntity ON documentEntity.id=storyStepEntity.document_id
  |ORDER BY position
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getLong(19),
      cursor.getString(20)
    )
  }

  public fun selectWithContent(): Query<SelectWithContent> = selectWithContent { id, title,
      created_at, last_updated_at, user_id, favorite, parent_document_id, id_, local_id, type,
      parent_id, url, path, text, checked, position, document_id, is_group, has_inner_steps,
      background_color, tags ->
    SelectWithContent(
      id,
      title,
      created_at,
      last_updated_at,
      user_id,
      favorite,
      parent_document_id,
      id_,
      local_id,
      type,
      parent_id,
      url,
      path,
      text,
      checked,
      position,
      document_id,
      is_group,
      has_inner_steps,
      background_color,
      tags
    )
  }

  public fun <T : Any> selectWithContentByIds(id: Collection<String>, mapper: (
    id: String,
    title: String,
    created_at: Long,
    last_updated_at: Long,
    user_id: String,
    favorite: Long,
    parent_document_id: String,
    id_: String?,
    local_id: String?,
    type: Long?,
    parent_id: String?,
    url: String?,
    path: String?,
    text: String?,
    checked: Long?,
    position: Long?,
    document_id: String?,
    is_group: Long?,
    has_inner_steps: Long?,
    background_color: Long?,
    tags: String?,
  ) -> T): Query<T> = SelectWithContentByIdsQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getLong(19),
      cursor.getString(20)
    )
  }

  public fun selectWithContentByIds(id: Collection<String>): Query<SelectWithContentByIds> =
      selectWithContentByIds(id) { id_, title, created_at, last_updated_at, user_id, favorite,
      parent_document_id, id__, local_id, type, parent_id, url, path, text, checked, position,
      document_id, is_group, has_inner_steps, background_color, tags ->
    SelectWithContentByIds(
      id_,
      title,
      created_at,
      last_updated_at,
      user_id,
      favorite,
      parent_document_id,
      id__,
      local_id,
      type,
      parent_id,
      url,
      path,
      text,
      checked,
      position,
      document_id,
      is_group,
      has_inner_steps,
      background_color,
      tags
    )
  }

  public fun <T : Any> selectWithContentByUserId(user_id: String, mapper: (
    id: String,
    title: String,
    created_at: Long,
    last_updated_at: Long,
    user_id: String,
    favorite: Long,
    parent_document_id: String,
    id_: String?,
    local_id: String?,
    type: Long?,
    parent_id: String?,
    url: String?,
    path: String?,
    text: String?,
    checked: Long?,
    position: Long?,
    document_id: String?,
    is_group: Long?,
    has_inner_steps: Long?,
    background_color: Long?,
    tags: String?,
  ) -> T): Query<T> = SelectWithContentByUserIdQuery(user_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getLong(19),
      cursor.getString(20)
    )
  }

  public fun selectWithContentByUserId(user_id: String): Query<SelectWithContentByUserId> =
      selectWithContentByUserId(user_id) { id, title, created_at, last_updated_at, user_id_,
      favorite, parent_document_id, id_, local_id, type, parent_id, url, path, text, checked,
      position, document_id, is_group, has_inner_steps, background_color, tags ->
    SelectWithContentByUserId(
      id,
      title,
      created_at,
      last_updated_at,
      user_id_,
      favorite,
      parent_document_id,
      id_,
      local_id,
      type,
      parent_id,
      url,
      path,
      text,
      checked,
      position,
      document_id,
      is_group,
      has_inner_steps,
      background_color,
      tags
    )
  }

  public fun <T : Any> selectFavoritesWithContentByUserId(user_id: String, mapper: (
    id: String,
    title: String,
    created_at: Long,
    last_updated_at: Long,
    user_id: String,
    favorite: Long,
    parent_document_id: String,
    id_: String?,
    local_id: String?,
    type: Long?,
    parent_id: String?,
    url: String?,
    path: String?,
    text: String?,
    checked: Long?,
    position: Long?,
    document_id: String?,
    is_group: Long?,
    has_inner_steps: Long?,
    background_color: Long?,
    tags: String?,
  ) -> T): Query<T> = SelectFavoritesWithContentByUserIdQuery(user_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getLong(19),
      cursor.getString(20)
    )
  }

  public fun selectFavoritesWithContentByUserId(user_id: String):
      Query<SelectFavoritesWithContentByUserId> = selectFavoritesWithContentByUserId(user_id) { id,
      title, created_at, last_updated_at, user_id_, favorite, parent_document_id, id_, local_id,
      type, parent_id, url, path, text, checked, position, document_id, is_group, has_inner_steps,
      background_color, tags ->
    SelectFavoritesWithContentByUserId(
      id,
      title,
      created_at,
      last_updated_at,
      user_id_,
      favorite,
      parent_document_id,
      id_,
      local_id,
      type,
      parent_id,
      url,
      path,
      text,
      checked,
      position,
      document_id,
      is_group,
      has_inner_steps,
      background_color,
      tags
    )
  }

  public fun <T : Any> selectWithContentByUserIdAfterTime(
    user_id: String,
    last_updated_at: Long,
    mapper: (
      id: String,
      title: String,
      created_at: Long,
      last_updated_at: Long,
      user_id: String,
      favorite: Long,
      parent_document_id: String,
      id_: String?,
      local_id: String?,
      type: Long?,
      parent_id: String?,
      url: String?,
      path: String?,
      text: String?,
      checked: Long?,
      position: Long?,
      document_id: String?,
      is_group: Long?,
      has_inner_steps: Long?,
      background_color: Long?,
      tags: String?,
    ) -> T,
  ): Query<T> = SelectWithContentByUserIdAfterTimeQuery(user_id, last_updated_at) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getLong(19),
      cursor.getString(20)
    )
  }

  public fun selectWithContentByUserIdAfterTime(user_id: String, last_updated_at: Long):
      Query<SelectWithContentByUserIdAfterTime> = selectWithContentByUserIdAfterTime(user_id,
      last_updated_at) { id, title, created_at, last_updated_at_, user_id_, favorite,
      parent_document_id, id_, local_id, type, parent_id, url, path, text, checked, position,
      document_id, is_group, has_inner_steps, background_color, tags ->
    SelectWithContentByUserIdAfterTime(
      id,
      title,
      created_at,
      last_updated_at_,
      user_id_,
      favorite,
      parent_document_id,
      id_,
      local_id,
      type,
      parent_id,
      url,
      path,
      text,
      checked,
      position,
      document_id,
      is_group,
      has_inner_steps,
      background_color,
      tags
    )
  }

  public fun <T : Any> selectWithContentById(id: String, mapper: (
    id: String,
    title: String,
    created_at: Long,
    last_updated_at: Long,
    user_id: String,
    favorite: Long,
    parent_document_id: String,
    id_: String?,
    local_id: String?,
    type: Long?,
    parent_id: String?,
    url: String?,
    path: String?,
    text: String?,
    checked: Long?,
    position: Long?,
    document_id: String?,
    is_group: Long?,
    has_inner_steps: Long?,
    background_color: Long?,
    tags: String?,
  ) -> T): Query<T> = SelectWithContentByIdQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7),
      cursor.getString(8),
      cursor.getLong(9),
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14),
      cursor.getLong(15),
      cursor.getString(16),
      cursor.getLong(17),
      cursor.getLong(18),
      cursor.getLong(19),
      cursor.getString(20)
    )
  }

  public fun selectWithContentById(id: String): Query<SelectWithContentById> =
      selectWithContentById(id) { id_, title, created_at, last_updated_at, user_id, favorite,
      parent_document_id, id__, local_id, type, parent_id, url, path, text, checked, position,
      document_id, is_group, has_inner_steps, background_color, tags ->
    SelectWithContentById(
      id_,
      title,
      created_at,
      last_updated_at,
      user_id,
      favorite,
      parent_document_id,
      id__,
      local_id,
      type,
      parent_id,
      url,
      path,
      text,
      checked,
      position,
      document_id,
      is_group,
      has_inner_steps,
      background_color,
      tags
    )
  }

  public fun <T : Any> selectWithContentByParentId(parent_document_id: String, mapper: (
    id: String,
    title: String,
    created_at: Long,
    last_updated_at: Long,
    user_id: String,
    favorite: Long,
    parent_document_id: String,
    id_: String,
    local_id: String,
    type: Long,
    parent_id: String?,
    url: String?,
    path: String?,
    text: String?,
    checked: Long,
    position: Long,
    document_id: String,
    is_group: Long,
    has_inner_steps: Long,
    background_color: Long?,
    tags: String,
  ) -> T): Query<T> = SelectWithContentByParentIdQuery(parent_document_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getLong(3)!!,
      cursor.getString(4)!!,
      cursor.getLong(5)!!,
      cursor.getString(6)!!,
      cursor.getString(7)!!,
      cursor.getString(8)!!,
      cursor.getLong(9)!!,
      cursor.getString(10),
      cursor.getString(11),
      cursor.getString(12),
      cursor.getString(13),
      cursor.getLong(14)!!,
      cursor.getLong(15)!!,
      cursor.getString(16)!!,
      cursor.getLong(17)!!,
      cursor.getLong(18)!!,
      cursor.getLong(19),
      cursor.getString(20)!!
    )
  }

  public fun selectWithContentByParentId(parent_document_id: String):
      Query<SelectWithContentByParentId> = selectWithContentByParentId(parent_document_id) { id,
      title, created_at, last_updated_at, user_id, favorite, parent_document_id_, id_, local_id,
      type, parent_id, url, path, text, checked, position, document_id, is_group, has_inner_steps,
      background_color, tags ->
    SelectWithContentByParentId(
      id,
      title,
      created_at,
      last_updated_at,
      user_id,
      favorite,
      parent_document_id_,
      id_,
      local_id,
      type,
      parent_id,
      url,
      path,
      text,
      checked,
      position,
      document_id,
      is_group,
      has_inner_steps,
      background_color,
      tags
    )
  }

  public suspend fun insert(
    id: String,
    title: String,
    created_at: Long,
    last_updated_at: Long,
    user_id: String,
    favorite: Long,
    parent_document_id: String,
  ) {
    driver.execute(740_513_187, """
        |INSERT INTO documentEntity(id, title, created_at, last_updated_at, user_id, favorite, parent_document_id)
        |VALUES (?, ?, ?, ?, ?, ?, ?)
        |ON CONFLICT(id) DO
        |UPDATE SET id=excluded.id, title=excluded.title, created_at=excluded.created_at,
        |last_updated_at=excluded.last_updated_at, user_id=excluded.user_id, favorite=excluded.favorite,
        |parent_document_id=excluded.parent_document_id
        """.trimMargin(), 7) {
          bindString(0, id)
          bindString(1, title)
          bindLong(2, created_at)
          bindLong(3, last_updated_at)
          bindString(4, user_id)
          bindLong(5, favorite)
          bindString(6, parent_document_id)
        }.await()
    notifyQueries(740_513_187) { emit ->
      emit("documentEntity")
    }
  }

  public suspend fun delete(id: String) {
    driver.execute(588_847_253, """DELETE FROM documentEntity WHERE id = ?""", 1) {
          bindString(0, id)
        }.await()
    notifyQueries(588_847_253) { emit ->
      emit("documentEntity")
    }
  }

  public suspend fun deleteByIds(id: Collection<String>) {
    val idIndexes = createArguments(count = id.size)
    driver.execute(null, """DELETE FROM documentEntity WHERE id IN $idIndexes""", id.size) {
          id.forEachIndexed { index, id_ ->
            bindString(index, id_)
          }
        }.await()
    notifyQueries(-621_663_412) { emit ->
      emit("documentEntity")
    }
  }

  public suspend fun deleteByUserId(user_id: String) {
    driver.execute(281_370_930, """DELETE FROM documentEntity WHERE user_id = ?""", 1) {
          bindString(0, user_id)
        }.await()
    notifyQueries(281_370_930) { emit ->
      emit("documentEntity")
    }
  }

  public suspend fun deleteByFolderId(parent_document_id: String) {
    driver.execute(375_450_133, """DELETE FROM documentEntity WHERE parent_document_id = ?""", 1) {
          bindString(0, parent_document_id)
        }.await()
    notifyQueries(375_450_133) { emit ->
      emit("documentEntity")
    }
  }

  public suspend fun favoriteById(id: String) {
    driver.execute(-323_311_880, """UPDATE documentEntity SET favorite = 1 WHERE id = ?""", 1) {
          bindString(0, id)
        }.await()
    notifyQueries(-323_311_880) { emit ->
      emit("documentEntity")
    }
  }

  public suspend fun unFavoriteById(id: String) {
    driver.execute(240_040_369, """UPDATE documentEntity SET favorite = 0 WHERE id = ?""", 1) {
          bindString(0, id)
        }.await()
    notifyQueries(240_040_369) { emit ->
      emit("documentEntity")
    }
  }

  public suspend fun moveToFolder(
    parent_document_id: String,
    last_updated_at: Long,
    id: String,
  ) {
    driver.execute(1_066_305_828,
        """UPDATE documentEntity SET parent_document_id = ?, last_updated_at = ? WHERE id = ?""", 3)
        {
          bindString(0, parent_document_id)
          bindLong(1, last_updated_at)
          bindString(2, id)
        }.await()
    notifyQueries(1_066_305_828) { emit ->
      emit("documentEntity")
    }
  }

  private inner class SelectWithContentByIdsQuery<out T : Any>(
    public val id: Collection<String>,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> {
      val idIndexes = createArguments(count = id.size)
      return driver.executeQuery(null, """
          |SELECT *
          |FROM documentEntity
          |LEFT JOIN storyStepEntity ON documentEntity.id=storyStepEntity.document_id
          |WHERE documentEntity.id IN $idIndexes
          |ORDER BY position
          """.trimMargin(), mapper, id.size) {
            id.forEachIndexed { index, id_ ->
              bindString(index, id_)
            }
          }
    }

    override fun toString(): String = "DocumentEntity.sq:selectWithContentByIds"
  }

  private inner class SelectWithContentByUserIdQuery<out T : Any>(
    public val user_id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-884_001_366, """
    |SELECT *
    |FROM documentEntity
    |LEFT JOIN storyStepEntity ON documentEntity.id=storyStepEntity.document_id
    |WHERE documentEntity.user_id = ?
    |ORDER BY position
    """.trimMargin(), mapper, 1) {
      bindString(0, user_id)
    }

    override fun toString(): String = "DocumentEntity.sq:selectWithContentByUserId"
  }

  private inner class SelectFavoritesWithContentByUserIdQuery<out T : Any>(
    public val user_id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(903_453_343, """
    |SELECT *
    |FROM documentEntity
    |LEFT JOIN storyStepEntity ON documentEntity.id=storyStepEntity.document_id
    |WHERE documentEntity.user_id = ? AND documentEntity.favorite = 1
    |ORDER BY position
    """.trimMargin(), mapper, 1) {
      bindString(0, user_id)
    }

    override fun toString(): String = "DocumentEntity.sq:selectFavoritesWithContentByUserId"
  }

  private inner class SelectWithContentByUserIdAfterTimeQuery<out T : Any>(
    public val user_id: String,
    public val last_updated_at: Long,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_628_166_081, """
    |SELECT *
    |FROM documentEntity
    |LEFT JOIN storyStepEntity ON documentEntity.id=storyStepEntity.document_id
    |WHERE documentEntity.user_id = ? AND last_updated_at > ?
    |ORDER BY position
    """.trimMargin(), mapper, 2) {
      bindString(0, user_id)
      bindLong(1, last_updated_at)
    }

    override fun toString(): String = "DocumentEntity.sq:selectWithContentByUserIdAfterTime"
  }

  private inner class SelectWithContentByIdQuery<out T : Any>(
    public val id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_693_935_231, """
    |SELECT *
    |FROM documentEntity
    |LEFT JOIN storyStepEntity ON documentEntity.id=storyStepEntity.document_id
    |WHERE documentEntity.id = ?
    |ORDER BY position
    """.trimMargin(), mapper, 1) {
      bindString(0, id)
    }

    override fun toString(): String = "DocumentEntity.sq:selectWithContentById"
  }

  private inner class SelectWithContentByParentIdQuery<out T : Any>(
    public val parent_document_id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("documentEntity", "storyStepEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_974_776_375, """
    |SELECT *
    |FROM documentEntity
    |JOIN storyStepEntity ON documentEntity.id=storyStepEntity.document_id
    |WHERE documentEntity.parent_document_id = ?
    |ORDER BY position
    """.trimMargin(), mapper, 1) {
      bindString(0, parent_document_id)
    }

    override fun toString(): String = "DocumentEntity.sq:selectWithContentByParentId"
  }
}
