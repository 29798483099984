package io.writeopia.sdk.sql

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Long
import kotlin.String

public class StoryStepEntityQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> selectByDocumentId(document_id: String, mapper: (
    id: String,
    local_id: String,
    type: Long,
    parent_id: String?,
    url: String?,
    path: String?,
    text: String?,
    checked: Long,
    position: Long,
    document_id: String,
    is_group: Long,
    has_inner_steps: Long,
    background_color: Long?,
    tags: String,
  ) -> T): Query<T> = SelectByDocumentIdQuery(document_id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getLong(2)!!,
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getLong(7)!!,
      cursor.getLong(8)!!,
      cursor.getString(9)!!,
      cursor.getLong(10)!!,
      cursor.getLong(11)!!,
      cursor.getLong(12),
      cursor.getString(13)!!
    )
  }

  public fun selectByDocumentId(document_id: String): Query<StoryStepEntity> =
      selectByDocumentId(document_id) { id, local_id, type, parent_id, url, path, text, checked,
      position, document_id_, is_group, has_inner_steps, background_color, tags ->
    StoryStepEntity(
      id,
      local_id,
      type,
      parent_id,
      url,
      path,
      text,
      checked,
      position,
      document_id_,
      is_group,
      has_inner_steps,
      background_color,
      tags
    )
  }

  public suspend fun insert(
    id: String,
    local_id: String,
    type: Long,
    parent_id: String?,
    url: String?,
    path: String?,
    text: String?,
    checked: Long,
    position: Long,
    document_id: String,
    is_group: Long,
    has_inner_steps: Long,
    background_color: Long?,
    tags: String,
  ) {
    driver.execute(-1_498_902_803, """
        |INSERT INTO storyStepEntity(id, local_id, type, parent_id, url, path, text, checked, position, document_id, is_group, has_inner_steps, background_color, tags)
        |VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)
        |ON CONFLICT(id) DO
        |UPDATE SET id=excluded.id, local_id=excluded.local_id, type=excluded.type, parent_id=excluded.parent_id,
        |url=excluded.url, path=excluded.path, text=excluded.text, checked=excluded.checked, position=excluded.position,
        |document_id=excluded.document_id, is_group=excluded.is_group, has_inner_steps=excluded.has_inner_steps,
        |background_color=excluded.background_color, tags=excluded.tags
        """.trimMargin(), 14) {
          bindString(0, id)
          bindString(1, local_id)
          bindLong(2, type)
          bindString(3, parent_id)
          bindString(4, url)
          bindString(5, path)
          bindString(6, text)
          bindLong(7, checked)
          bindLong(8, position)
          bindString(9, document_id)
          bindLong(10, is_group)
          bindLong(11, has_inner_steps)
          bindLong(12, background_color)
          bindString(13, tags)
        }.await()
    notifyQueries(-1_498_902_803) { emit ->
      emit("storyStepEntity")
    }
  }

  public suspend fun deleteByDocumentId(document_id: String) {
    driver.execute(-67_930_420, """
        |DELETE
        |FROM storyStepEntity
        |WHERE document_id = ?
        """.trimMargin(), 1) {
          bindString(0, document_id)
        }.await()
    notifyQueries(-67_930_420) { emit ->
      emit("storyStepEntity")
    }
  }

  private inner class SelectByDocumentIdQuery<out T : Any>(
    public val document_id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("storyStepEntity", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("storyStepEntity", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(2_054_337_693, """
    |SELECT *
    |FROM storyStepEntity
    |WHERE document_id = ?
    """.trimMargin(), mapper, 1) {
      bindString(0, document_id)
    }

    override fun toString(): String = "StoryStepEntity.sq:selectByDocumentId"
  }
}
