package io.writeopia.sdk.sql

import kotlin.Long
import kotlin.String

public data class SelectWithContentByParentId(
  public val id: String,
  public val title: String,
  public val created_at: Long,
  public val last_updated_at: Long,
  public val user_id: String,
  public val favorite: Long,
  public val parent_document_id: String,
  public val id_: String,
  public val local_id: String,
  public val type: Long,
  public val parent_id: String?,
  public val url: String?,
  public val path: String?,
  public val text: String?,
  public val checked: Long,
  public val position: Long,
  public val document_id: String,
  public val is_group: Long,
  public val has_inner_steps: Long,
  public val background_color: Long?,
  public val tags: String,
)
