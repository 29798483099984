package io.writeopia.features.search

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

@Composable
fun DocumentsSearchScreen() {
    Box(modifier = Modifier.fillMaxSize()) {
        Text("io.writeopia.common.utils.icons.all.getSearch is yet to be implemented", modifier = Modifier.align(Alignment.Center))
    }
}
